@use 'variables';
@use 'colors';
@use 'ui/buttons/common';

.app-title {
  font-family: variables.$font-name-header;
  font-size: variables.$font-size-large;
  color: colors.$yellow-light;
  text-align: center;
  line-height: 0.9;
}

.app-subtitle {
  color: colors.$yellow-fire;
}

.app-input {
  height: 60px;
  padding: 0 20px;
  font-family: variables.$font-name-default;
  color: inherit;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;

  &--small {
    width: 92px;
    background-image: url('/assets/images/ui/elements/input/small.avif');

    &:disabled {
      background-image: url('/assets/images/ui/elements/input/small_disabled.avif');
      color: #796b5d;
    }
  }

  &--medium {
    width: 154px;
    background-image: url('/assets/images/ui/elements/input/medium.avif');

    &:disabled {
      background-image: url('/assets/images/ui/elements/input/medium_disabled.avif');
      color: #796b5d;
    }
  }

  &--large {
    width: 295px;
    background-image: url('/assets/images/ui/elements/input/large.avif');

    &:disabled {
      background-image: url('/assets/images/ui/elements/input/large_disabled.avif');
      color: #796b5d;
    }
  }

  &--huge {
    width: 381px;
    background-image: url('/assets/images/ui/elements/input/huge.avif');

    &:disabled {
      background-image: url('/assets/images/ui/elements/input/huge_disabled.avif');
      color: #796b5d;
    }
  }

  &--nickname {
    width: 850px;
    height: 93px;
    background-image: url('/assets/images/ui/elements/input/nickname.avif');

    &:disabled {
      background-image: url('/assets/images/ui/elements/input/nickname_disabled.avif');
      color: #796b5d;
    }
  }
}

.app-text-warning {
  color: colors.$yellow-fire;
}

.app-text-error {
  color: colors.$red-fire;
}

.app-text-warning--shadow {
  text-shadow: 0 0 16px #ff0000;
}

.app-text-yellow-fire--shadow {
  text-shadow: 0 0 16px colors.$yellow-fire;
}

.text-shadow {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.75), 0 0 5px #000000, 3px 3px 4px rgba(0, 0, 0, 0.52);
}

.text-bar-shadow {
  text-shadow: 0 0 8px #000000, 0 0 5px #000000, 0 0 5px #000000, 0 0 8px #000000, 0 0 3px #000000, 0 0 2px #000000;
}

.text-shadow-blue {
  text-shadow: 0 0 8px rgba(89, 118, 255, 0.45);
}

.title-a {
  @extend .text-shadow;

  font-family: variables.$font-name-header;
}

.title-b {
  @extend .text-shadow;

  font-family: variables.$font-name-default;
}

.text-shadow-positive {
  text-shadow: 0 0 10px #ff6b00;
}

.text-shadow-negative {
  text-shadow: 0 0 10px #ff0000;
}

.text-speech {
  color: colors.$speech;
}

.text-no-wrap {
  white-space: nowrap;
}
