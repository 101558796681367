.app-paint-text {
  background-image: url('/assets/images/ui/paint/for-text/black-paint-for-text.avif');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.app-paint-text-2 {
  background-image: url('/assets/images/ui/paint/for-text/black-paint-for-text-2.avif');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}
