// dialogues backgrounds

.app-window-background {
  display: block;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;
}

.app-window-ask-for-reinforcement-background {
  @extend .app-window-background;

  background-image: url('/assets/images/ui/windows-pop-ups/window-ask-for-reinforcement.avif');
  width: 999px;
  height: 757px;
}

.app-window-full-screen-background {
  @extend .app-window-background;

  background-image: url('/assets/images/ui/windows-pop-ups/window-full-screen.avif');
  width: calc(100% + 10px);
  height: 100%;
  max-width: 1100px;
  max-height: 100%;
  background-repeat: no-repeat;
  background-position: 0px -2px;
}

.app-window-item-info-for-forge-page-background {
  @extend .app-window-background;

  background-image: url('/assets/images/ui/windows-pop-ups/window-item-info-for-forge-page.avif');
  width: 1090px;
  height: 1190px;
  max-width: 100%;
  max-height: 100%;
}

.app-window-responsive-background {
  width: 990px;
  max-width: 1080px;
  height: fit-content;
  //min-height: 300px;
  max-height: 100%;
  //margin-top: 220px;
  padding-bottom: 20px;
  border: 3px solid #524b43;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 31.69%, rgba(0, 0, 0, 0.20) 88.03%), url('/assets/images/ui/windows-pop-ups/window-responsive.avif') lightgray 0% 0% / 124.02597665786743px 124.02597665786743px repeat;
  box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.45) inset, 0 0 40px 0 #000000, 0 0 10px 0 rgba(0, 0, 0, 0.80);

  &--full {
    width: 96%;
    height: 100%;
    max-width: 1100px;
    max-height: 80%;
  }

  &--full-width {
    width: 100%;
    height: 100%;
    max-width: 1100px;
    max-height: 80%;
  }

  &--full-height {
    width: 100%;
    height: 100%;
    max-width: 1100px;
    max-height: 100%;
    z-index: 999998;
  }
}
