// override CDK dialog styles

/*.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;

  &.cdk-overlay-backdrop-showing {
    opacity: 1;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}*/


.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  overflow: hidden;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

.cdk-overlay-pane {
  //position: absolute;
  //pointer-events: auto;
  box-sizing: border-box;
  //z-index: 1000;
  display: flex;
  //max-width: 100%;
  //max-height: 100%;
  width: 100%;
  height: 100%;
  //overflow: auto;
  overflow: hidden;
}

.cdk-overlay-container {
  z-index: 999997;
  //overflow-y: auto;
  overflow: hidden;

  &:empty {
    display: none;
  }
}

.cdk-overlay-backdrop {
  backdrop-filter: blur(5px);
}

//.app-overlay-pane-full-width,
//.app-overlay-pane {
//  width: 100%;
//  backdrop-filter: blur(5px);
//
//  .cdk-dialog-container {
//    // todo just for example
//    box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
//  }
//}

.cdk-dialog-container {
  overflow: hidden;
}

.app-overlay-pane-full-width {
  width: 100%;
  height: 100%;
}

// CDK menu

.cdk-overlay-connected-position-bounding-box {
  position: fixed;

  .cdk-overlay-pane {
    justify-content: end;
  }
}

.cdk-menu {
  z-index: 9999;
}
