// all-hero-stats-page

.app-bg-all-hero-stats-back {
  background-image: url('/assets/images/ui/background-lists/all-hero-stats-page/all-hero-page-back.avif');
  background-repeat: no-repeat;
  background-position: center top;
  width: 1080px;
  height: 1244px;
}

$app-bg-all-hero-stats-front_width: 533px;
.app-bg-all-hero-stats-front {
  background-image: url('/assets/images/ui/background-lists/all-hero-stats-page/all-hero-page-front.avif');
  background-repeat: no-repeat;
  background-position: center top;
  width: $app-bg-all-hero-stats-front_width;
  height: 839px;
}

.app-bg-all-hero-stats-back-new {
  background-image: url('/assets/images/ui/background-lists/all-hero-stats-page/all-hero-page-back-new.avif');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  width: 100%;
  height: 100%;
}

// inventory
$app-bg-inventory-equipped-front_width: 786px;
.app-bg-inventory-equipped-front {
  background-image: url('/assets/images/ui/background-lists/inventory/equiped-page-back.avif');
  background-repeat: no-repeat;
  background-position: center top;
  width: $app-bg-inventory-equipped-front_width;
  height: 916px;
}

// level-up-page

$app-bg-level-up-back_width: 1064px;
$app-bg-level-up-back_height: 1011px;
.app-bg-level-up-back,
.app-bg-reinforcement-back,
.app-bg-inventory-all-back {
  background-image: url('/assets/images/ui/background-lists/all-hero-stats-page/all-hero-page-back-new.avif');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  width: 100%;
  height: 1461px;
}
$app-bg-level-up-front_width: 786px;
$app-bg-level-up-front_height: 916px;
.app-bg-level-up-front {
  background-image: url('/assets/images/ui/background-lists/inventory/equiped-page-back.avif');
  background-repeat: no-repeat;
  background-position: center top;
  width: 1185px;
  height: 1230px;
  background-size: contain;
}

// main-page
$app-bg-main-back_width: 1024px;
.app-bg-main-back,
.app-bg-inventory-equipped-back {
  background-image: url('/assets/images/ui/background-lists/main-page/back.avif');
  background-repeat: no-repeat;
  background-position: center top;
  width: $app-bg-main-back_width;
  height: 1054px;
}
$app-bg-main-front_width: 620px;
.app-bg-main-front {
  background-image: url('/assets/images/ui/background-lists/main-page/front.avif');
  background-repeat: no-repeat;
  background-position: center top;
  width: $app-bg-main-front_width;
  height: 871px;
}
