@use 'common';
@use 'shadows';
@use 'ui/typography';
@use 'colors';

$_path: common.$buttons-path;

$app-btn-daily_width: 495px;
$app-btn-daily_height: 156px;
.app-btn-daily {
  @extend .title-a, .app-btn-medium_font-size, .color-yellow-fire;

  width: $app-btn-daily_width;
  height: $app-btn-daily_height;
  padding-left: 90px;

  background-image: url($_path + 'daily/default.avif');
  &.app-btn_active {
    background-image: url($_path + 'daily/active.avif'), url($_path + 'daily/default.avif');
  }

  &:hover:not(:disabled):not(:active) {
    @extend .text-shadow-positive;

    background-image: url($_path + 'daily/hover.avif');

    &.app-btn_active {
      background-image: url($_path + 'daily/active.avif'), url($_path + 'daily/hover.avif');
    }
  }

  &:active:not(:disabled) {
    @extend .text-shadow;

    background-image: url($_path + 'daily/pressed.avif');
    color: colors.$yellow-light;

    &.app-btn_active {
      background-image: url($_path + 'daily/active.avif'), url($_path + 'daily/pressed.avif');
    }
  }

  &.disabled,
  &:disabled,
  &:active.disabled {
    background-image: url($_path + 'daily/disabled.avif');
  }
}
