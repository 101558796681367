@font-face {
  font-family: 'Cambria';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/cambria/cambria.ttf') format('truetype');
}

@font-face {
  font-family: 'Cambria';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/cambria/Сambria_bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Cambria';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/cambria/Cambria_italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Cambria';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/cambria/Cambria_bold_italic.ttf') format('truetype');
}

@font-face {
  font-family: 'SellYouSoul';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/sell_your_soul/SellYourSoulExtended2.ttf') format('truetype');
  //src: url('../fonts/sell_your_soul/Sacra-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Sacra';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/Sacra-Regular.ttf') format('truetype');
}
