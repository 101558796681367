@use 'colors';

/*  #CUSTOM SCROLL
------------------------------------------- */
$g-custom-scroll-bg: rgba(colors.$theme-primary, 0.3);
$g-custom-scroll-hover-bg: rgba(colors.$theme-primary, 0.5);

$scroll-bar-width: 0px;
$scroll-bar-width-small: 4px;

// # CUSTOM SCROLL
//===========================================
.custom-scroll {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  // [ Chrome settings ]
  &::-webkit-scrollbar {
    width: $scroll-bar-width;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: transparent;

    // [ State ] hover
    &:hover {
      background: transparent;
    }
  }
}

// [ MODE ] CUSTOM SCROLL - SMALL
//===========================================
.custom-scroll-small {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  // [ Chrome settings ]
  &::-webkit-scrollbar {
    width: $scroll-bar-width-small;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: $g-custom-scroll-bg;

    // [ State ] hover
    &:hover {
      background: $g-custom-scroll-hover-bg;
    }
  }
}

.custom-scroll--horizontal {
  // [ Chrome settings ]
  &::-webkit-scrollbar {
    height: $scroll-bar-width;
  }
}

.custom-scroll-small--horizontal {
  // [ Chrome settings ]
  &::-webkit-scrollbar {
    height: $scroll-bar-width-small;
  }
}
